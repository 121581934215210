<div id="modal-login" class="uk-modal-full uk-modal-container" uk-modal esc-close="false" bg-close="false"
  style="visibility: hidden;">
  <div class="uk-modal-dialog uk-margin-auto-vertical uk-modal-body uk-margin-xlarge-left uk-margin-xlarge-right">
    <div class="uk-text-center" uk-grid>
      <div class="uk-width-auto">
        <span uk-icon="user"></span>
      </div>
      <div class="uk-width-expand">
        <h3 class="uk-modal-title uk-heading-small uk-heading-line uk-text-center"><span>Login</span></h3>
      </div>
      <div class="uk-width-auto">
        <span uk-icon="user"></span>
      </div>
    </div>
    <div class="uk-modal-body uk-padding-remove-bottom">
      <form class="uk-form-stacked">
          <div class="uk-form-row uk-margin-bottom">
            <input id="login-name" class="uk-input" type="text" required="true" placeholder="Name"
              aria-label="Name">
          </div>
          <div class="uk-form-row uk-margin-bottom">
            <input id="login-password" class="uk-input" type="password" required="true" placeholder="Password"
              aria-label="Password">
          </div>
      </form>
    </div>

    <div id="login-actions" class="uk-inline uk-width-expand">
      <div id="login-loading" class="uk-position-bottom-center uk-margin-remove-top uk-invisible" uk-spinner></div>
      <button id="login-action" class="uk-button uk-button-primary uk-align-center uk-margin-remove-top uk-margin-remove-bottom" type="button">
        Login
      </button>
    </div>
  </div>
</div>